import { StyleSheet, TextInputProps } from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import { palettes } from "../../../config";
import Label from "../label/Label";

type LabelProps = {
    decoration?: JSX.Element;
    label?: string;
    labelProps?: TextInputProps;
    error?: string;
    actionText?: string;
}

type Props = {
    data: any[],
    setValue: (e: string) => void;
    value: string,
    placeHolder: string
} & LabelProps;


const DropDownContainer = ({
    data,
    value,
    actionText,
    labelProps,
    error,
    label,
    placeHolder = "Select item",
    setValue,
}: Props) => {
    const labelStyle = labelProps?.style;
    delete labelProps?.style;
    return <>
        {!!label && (
            <Label
                error={error}
                label={label}
                labelStyle={labelStyle}
                actionText={actionText}
            />
        )}
        <Dropdown
            style={styles.dropdown}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            iconStyle={styles.iconStyle}
            data={data}
            search
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder={placeHolder}
            searchPlaceholder="Search..."
            value={value}
            onChange={item => {
                setValue(item.value);
            }}
        />
    </>
}

export default DropDownContainer

const styles = StyleSheet.create({
    dropdown: {
        // margin: 16,
        padding: 10,
        height: 50,
        borderColor: palettes?.grey[4],
        borderWidth: 1,
        borderRadius: 6
    },
    icon: {
        marginRight: 5,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});