import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../common/fragments';
export const GET_NEW_BRANDS = gql`
	${ADDRESS_FRAGMENT}
	query getNewBrands($current: Int, $limit: Int, $sort: JSON) {
		getNewBrands(current: $current, limit: $limit, sort: $sort) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						totalActive
						totalInactive
						newRequest
						data {
							... on Brand {
								_id
								name
								website
								companyId
								vatId
								profileImage
								email
								additionalMedia
								description
								address {
									...AddressFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_BRANDS = gql`
	${ADDRESS_FRAGMENT}
	query getAllBrands(
		$find: BrandQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllBrands(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						totalActive
						totalInactive
						newRequest
						data {
							... on Brand {
								_id
								name
								website
								companyId
								vatId
								profileImage
								email
								additionalMedia
								description
								address {
									...AddressFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;
// connectAccountInfo {
//  connectAccountId
// }
export const GET_BRAND_STATS = gql`
	query getBrandStats($_ids: [ID!]) {
		getBrandStats(brandIds: $_ids) {
			statusCode
			success
			data {
				total
				data {
					... on BrandStats {
						brandId
						# followers {
						#   sellersCount
						#   guestCount
						#   hostCount
						# }
						eventsCount
						followersCount
						productsCount
					}
				}
			}
		}
	}
`;
export const GET_BRAND_GRAPH_DATA = gql`
	query getBrandsGraphData {
		getBrandsGraphData {
			statusCode
			success
			message
			data {
				... on GraphDataResponse {
					previousMonth {
						dataPoint
						week
					}
					currentMonth {
						dataPoint
						week
					}
				}
			}
		}
	}
`;
export const GET_SINGLE_BRAND = gql`
	query getBrand($_id: ID!) {
		getBrand(_id: $_id) {
			statusCode
			success
			message
			data {
				... on Brand {
					_id
					name
					profileImage
					companyId
					connectAccountInfo{
						connectAccountId
					}
					vatId
					address {
						countryLabel
						countryCode
						city
						zipCode
						region
						address1
					}
					phoneNumber
					email
					website
					socialReason
					vat
					products(populate: ["images"]) {
						data {
							... on Product {
								_id
								name
								shortDescription
								rating
								images {
									_id
									src
									alt
								}
								variants {
									_id
									image {
										src
									}
									option {
										values {
											price
											name
										}
									}
								}
							}
						}
					}

					rating
					additionalMedia
					numericSignature
					tags
					description
					kybStatus
					description
					brandAllowedCountries {
						sn
						countryCode
						shippingFee
						paymentOption
						freeShippingThreshold
						exchangeRate
					}
					bankAccount {
						accountName
						IBAN
						bankName
					}
				}
			}
		}
	}
`;
export const GET_BRANDS_REVENUE = gql`
	query getBrandTrxDetails($ids: [ID]) {
		getBrandTrxDetails(ids: $ids) {
			success
			statusCode
			data {
				total
				... on All {
					data {
						... on BrandEarning {
							brandId
							currency
							grossEarnings
							# availableEarning
						}
					}
				}
			}
		}
	}
`;

export const GET_PENDING_TRANSFER = gql`
	query getPendingTransfer($args: GetPendingTransfer) {
		getPendingTransfer(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on PendingTransfer {
								amount
								sellerId
								corresponingChargeIdsAndAmount {
									chargeId
									amount
								}
							}
						}
					}
				}
			}
		}
	}
`;
